<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
.landing-prizes {
  @apply flex flex-col w-full items-center;
  // height: 70vh;
  background-image: url('/images/prizes-bg.svg');
  // background: #1a1a1b;
  background-position: bottom;
  @apply bg-no-repeat bg-cover;
  padding-bottom: 100px;
  .content-wrapper {
    @apply flex flex-row w-full justify-between z-20;
    max-width: 1200px;
    .prizes-img {
      background: url('/images/prizes.png');
      width: 305px;
      height: 79.3px;
      @apply bg-no-repeat bg-no-repeat bg-contain;
    }
    .prizes-description {
      h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 130%;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
      }
      h2 {
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;
        opacity: 0.7;
        margin-top: 20px;
        font-weight: 200;
        font-family: 'NeueHaas';
        max-width: 340px;
      }
      h3 {
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;
        opacity: 0.85;
        margin-top: 32px;
        margin-bottom: 16px;
        font-family: 'NeueHaas';
      }
      .dot {
        font-size: 16px;
        line-height: 120%;
        color: #fff;
        margin-bottom: 8px;
        @apply flex flex-row items-center;
        font-family: 'NeueHaas-Medium';
        &:before {
          width: 12px;
          height: 12px;
          background: #4B4444;
          border-radius: 50%;
          margin-right: 8px;
          content: '';
        }
        span {
          opacity: 0.7;
          margin-right: 6px;
        }
      }
    }
    .prizes-content {
      @apply flex flex-col items-center;
      .prizes {
        @apply flex flex-row w-full justify-center;
        .prize {
          @apply w-full flex flex-col;
          // border: 1px solid red;
          max-width: 200px;
          margin-right: 30px;
          h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            color: #DAF6FF;
            font-family: 'NeueHaas-Bold';
          }
          p {
            font-size: 16px;
            line-height: 105%;
            color: #FFFFFF;
            opacity: 0.7;
            margin-top: 12px;
            font-family: 'NeueHaas-Medium';
          }
        }
      }
      .socks-img {
        background: url('/images/socks.svg');
        width: 79.98px;
        height: 68.77px;
        // border: 1px solid red;
        margin-top: 20px;
        margin-bottom: 10px;
        @apply bg-contain bg-center bg-no-repeat;
      }
      .socks {
        @apply w-full flex flex-row items-center;
        .line {
          @apply w-full;
          opacity: 0.4;
          border: 1px solid #FFFFFF;
        }
        p {
          font-weight: normal;
          font-family: 'NeueHaas-Medium';
          font-size: 16px;
          line-height: 105%;
          color: #fff;
          white-space: nowrap;
          margin: 0 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .landing-prizes {
    .content-wrapper {
      padding: 0 10px;
      @apply flex-col items-center;
      .prizes-description {
        padding-bottom: 50px;
        margin-bottom: 50px;
        border-bottom: 1px solid #707070;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .landing-prizes {
    margin-top: -70px;
    .content-wrapper {
      // @apply items-center;
      .prizes-description {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid #707070;
      }
      .prizes-content {
        .prizes {
          @apply flex-col w-full items-center;
          .prize {
            @apply items-center;
            max-width: 100%;
            margin-right: 0px;
            margin-bottom: 30px;
            text-align: center;
            h2 {
              font-size: 20px;
            }
            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
<template lang="pug">
.landing-prizes
  .content-wrapper
    .prizes-description
      h1 Призы лучшим
      h2 {{desc}}
      h3 Закрытие таблиц:
      div(v-for="(dot, dotInd) in dots" :key="dotInd").dot
        <span>{{dot.title + ' '}}</span>{{dot.time}}
    .prizes-content
      .prizes
        div(v-for="(prize, prizeIndex) in prizes" :key="prizeIndex").prize
          h2 {{prize.place}}
          p {{prize.description}}
      .socks-img
      .socks
        .line
        p Все 3 места получат носки
        .line
</template>

<script>
import LandingBtn from '@/components/LandingPage/LandingBtn.vue';

export default {
  name: 'LandingCover',
  components: {
    LandingBtn,
  },
  data() {
    return {
      desc: 'В зависимости от времени прохождения вы попадёте в разные турнирные таблицы со своим топом победителей.',
      dots: [
        {
          title: 'Первая до ',
          time: '18 Ноября 14:30',
        },
        {
          title: 'Вторая до ',
          time: '18 Ноября 17:45',
        },
        {
          title: 'Третья до ',
          time: '19 Ноября 14:30',
        },
        {
          title: 'Четвертая до ',
          time: '19 Ноября 17:45',
        },
      ],
      prizes: [
        {
          place: '1 место',
          description: 'Умный термостакан, поддерживать водный баланс - и не только',
        },
        {
          place: '2 место',
          description: 'Powerbank на 10000 mAh всегда быть в коннекте',
        },
        {
          place: '3 место',
          description: 'Кофер - самый трендовый стакан для горячего кофе с покрытием softtouch',
        },
      ],
    };
  },
  methods: {},
};
</script>
