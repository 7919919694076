<style lang="scss" scoped>
.feedback {
  @apply relative flex flex-col items-start h-full;
  .title {
    @apply font-normalidad-wide w-max px-8;
    font-size: 20px;
    --height: 48px;
    line-height: var(--height);
    height: var(--height);
    background: #FFDD2D;
    box-shadow: 6px 6px 0px #000000;
    font-size: 40px;
    color: #000000;
  }
}

.feedback-button-container {
  margin: 0 -2px 12px;
}
.feedback-button {
  @apply bg-center bg-no-repeat;
  --size: 54px;
  width: var(--size);
  height: var(--size);
  margin: 0 2px;
  background-color: #FFE0B1;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.44494 0.578234C9.67688 -0.192746 10.6868 -0.192744 10.9187 0.578236L12.7911 6.80232C12.8949 7.14711 13.1924 7.38056 13.528 7.38056H19.5873C20.3379 7.38056 20.65 8.4179 20.0428 8.8944L15.1407 12.7411C14.8691 12.9542 14.7555 13.3319 14.8592 13.6767L16.7316 19.9008C16.9636 20.6718 16.1466 21.3129 15.5393 20.8364L10.6372 16.9897C10.3657 16.7766 9.99796 16.7766 9.7264 16.9897L4.8243 20.8364C4.21708 21.3129 3.40006 20.6718 3.632 19.9008L5.50444 13.6767C5.60816 13.3319 5.49453 12.9542 5.22297 12.7411L0.320871 8.89439C-0.286355 8.4179 0.02572 7.38056 0.776292 7.38056H6.83562C7.17128 7.38056 7.46877 7.14711 7.5725 6.80232L9.44494 0.578234Z' fill='%23D2C0AA'/%3E%3C/svg%3E%0A");
  box-shadow: 4px 4px 0px #000000;
  border-radius: 2px;
  transition: background-image 0.25s;
  &:hover:not(:disabled) {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.2633 1.07823C10.4952 0.307254 11.5051 0.307256 11.7371 1.07824L13.6095 7.30232C13.7132 7.64711 14.0107 7.88056 14.3464 7.88056H20.4057C21.1563 7.88056 21.4683 8.9179 20.8611 9.3944L15.959 13.2411C15.6875 13.4542 15.5738 13.8319 15.6776 14.1767L17.55 20.4008C17.7819 21.1718 16.9649 21.8129 16.3577 21.3364L11.4556 17.4897C11.184 17.2766 10.8163 17.2766 10.5448 17.4897L5.64266 21.3364C5.03543 21.8129 4.21842 21.1718 4.45036 20.4008L6.32279 14.1767C6.42652 13.8319 6.31289 13.4542 6.04133 13.2411L1.13923 9.39439C0.532005 8.9179 0.844079 7.88056 1.59465 7.88056H7.65398C7.98964 7.88056 8.28713 7.64711 8.39086 7.30232L10.2633 1.07823Z' fill='black'/%3E%3C/svg%3E%0A");
  }
  &.selected {
    background-color: #FFDD2D;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.2633 1.07823C10.4952 0.307254 11.5051 0.307256 11.7371 1.07824L13.6095 7.30232C13.7132 7.64711 14.0107 7.88056 14.3464 7.88056H20.4057C21.1563 7.88056 21.4683 8.9179 20.8611 9.3944L15.959 13.2411C15.6875 13.4542 15.5738 13.8319 15.6776 14.1767L17.55 20.4008C17.7819 21.1718 16.9649 21.8129 16.3577 21.3364L11.4556 17.4897C11.184 17.2766 10.8163 17.2766 10.5448 17.4897L5.64266 21.3364C5.03543 21.8129 4.21842 21.1718 4.45036 20.4008L6.32279 14.1767C6.42652 13.8319 6.31289 13.4542 6.04133 13.2411L1.13923 9.39439C0.532005 8.9179 0.844079 7.88056 1.59465 7.88056H7.65398C7.98964 7.88056 8.28713 7.64711 8.39086 7.30232L10.2633 1.07823Z' fill='black'/%3E%3C/svg%3E%0A");
  }
  &:disabled {
    @apply cursor-not-allowed;
  }
}

.comment {
  @apply font-normalidad w-full flex-grow;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  background: #F2F2F2;
  box-shadow: 6px 6px 0px #000000;
  &:focus {
    outline: none;
  }
}
.menu-button {
  @apply font-normalidad-wide uppercase absolute;
  font-weight: 350;
  font-size: 10px;
  right: 12px;
  top: 14px;
  padding-right: 21px;
  padding-left: 22px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: linear-gradient(145deg, rgba(0, 0, 0, 0) 8px, rgba(0, 0, 0, 0.4) 0) left,
    linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0px, rgba(0, 0, 0, 0.4) 0) right;
  background-size: 50% 100%;
  background-repeat: no-repeat;
}
</style>

<template>
  <div class="feedback game-main">
    <div class="flex-grow flex flex-col items-start justify-end mb-16 w-full">
      <div class="title mt-4 mb-20">
        Оцени
      </div>
      <div class="feedback-button-container">
        <button
          v-for="i in 5"
          :key="i"
          class="feedback-button"
          :class="{ selected: i <= selected }"
          @click="setSelected(i)"
        ></button>
      </div>
      <textarea v-model="comment" class="comment" placeholder="Напиши отзыв"></textarea>
    </div>
    <div class="mt-16 w-full">
      <button
        class="btn-primary mb-12"
        :class="{ loading: loading }"
        :disabled="!comment"
        @click="sendAndGoToResult"
      >
        Отправить
      </button>
      <router-link class="btn-secondary" :to="{ name: 'Result' }">В другой раз</router-link>
    </div>
    <ModalSettings />
  </div>
</template>

<script>
import sendFeedback from '@/api/sendFeedback';
import ModalSettings from '@/components/Modals/ModalSettings.vue';
import {
  ref, reactive, toRefs, watch,
} from '@vue/composition-api';

export default {
  name: 'FeedbackBlock',
  components: {
    ModalSettings,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {},
  setup() {
    const state = reactive({
      isLoading: false,
      error: null,
      success: null,
    });
    const selected = ref(null);
    const comment = ref(null);

    const estimate = async () => {
      state.isLoading = true;
      state.error = null;
      state.success = null;
      try {
        await sendFeedback({
          rating: selected.value,
          comment: comment.value,
        });
        state.success = true;
      } catch (e) {
        state.error = e;
      } finally {
        state.isLoading = false;
      }
    };

    const setSelected = (id) => {
      selected.value = id;
    };

    watch(selected, estimate);

    return {
      ...toRefs(state),
      estimate,
      selected,
      setSelected,
      comment,
    };
  },
  created() {},
  mounted() {},
  methods: {
    goToResult() {
      this.$router.push({ name: 'Result' });
    },
    sendAndGoToResult() {
      this.loading = true;
      this.estimate().then(this.goToResult);
    },
    openSettings() {
      this.$modal.show('settings-modal');
    },
  },
  computed: {},
  watch: {
    success(val) {
      if (val) {
        // console.log('success', val);
      }
    },
    error(val) {
      if (val) {
        // console.log('error', val);
      }
    },
  },
};
</script>
