<style lang="scss" scoped>
.landing-lecturers {
  @apply flex flex-col w-full items-center;
  // height: 70vh;
  background-position: bottom;
  .content-wrapper {
    @apply flex flex-row w-full justify-between z-20;
    max-width: 1200px;
    margin-top: 50px;
    .line {
      margin: 0 85px;
      // height: 100%;
      width: 1px;
      background: #BFBFBE;
      // border: 1px solid red;
    }
    .left {
      @apply w-full flex flex-col;
      h1 {
        font-size: 48px;
        line-height: 100%;
        // margin-bottom: 32px;
        font-family: 'TinkoffSans';
      }
      .lecture {
        @apply w-full flex flex-col items-start justify-start;
        padding: 32px 0px;
        border-bottom: 1px solid #BFBFBE;
        .header {
          @apply flex flex-row;
          margin-bottom: 16px;
          .dot {
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            font-family: 'NeueHaas-Medium';
            color: #2C2C2C;
            margin-right: 24px;
            @apply flex flex-row items-center;
            &:before {
              content: '';
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: #FF5A56;
              margin-right: 8px;
            }
          }
          p {
            color: #5E5E5E;
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            font-family: 'NeueHaas-Medium';
          }
        }
        p {
          font-weight: bold;
          font-family: 'NeueHaas-Bold';
          font-size: 24px;
          line-height: 120%;
          color: #000000;
        }
        .author {
          @apply flex relative w-full flex-row;
          margin-top: 32px;
          .avatar {
            @apply bg-contain bg-center bg-no-repeat;
            width: 85px;
            height: 85px;
            margin-right: 24px;
          }
          .who {
            @apply flex flex-col h-full justify-center;
            max-width: 200px;
            .name {
              font-weight: bold;
              font-size: 18px;
              line-height: 130%;
              font-family: 'NeueHaas-Bold';
            }
            .role {
              font-size: 17px;
              line-height: 120%;
              font-family: 'NeueHaas-Medium';
            }
          }
          .rofl {
            @apply w-full h-full absolute bg-no-repeat bg-contain;
            background-position: center right;
            height: 120%;
            margin-top: -40px;
            // border: 1px solid red;
          }
        }
      }
    }
    .right {
      @apply w-full flex flex-col;
      h1 {
        font-size: 48px;
        line-height: 100%;
        margin-bottom: 32px;
        font-family: 'TinkoffSans';
      }
    }
  }
}
.rofl-mobile {
  @apply w-full bg-no-repeat bg-contain;
  background-position: center right;
  // margin-top: -40px;
  // border: 1px solid red;
  height: 100px;
  display: none;
}
@media screen and (max-width: 1200px) {
  .landing-lecturers {
    .content-wrapper {
      padding: 0 10px;
      @apply flex-col;
      .line {
        display: none;
      }
      .left {
        h1 {
          font-size: 42px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .rofl-mobile {
    display: inherit;
  }
  .landing-lecturers {
    padding-bottom: 100px;
    .content-wrapper {
      .left {
        margin-bottom: 50px;
        .lecture {
          .author {
            .rofl {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
<template lang="pug">
.landing-lecturers
  .content-wrapper
    .left
      h1 Наши лекторы
      div(v-for="(lecture, lectureIndex) in lecturers" :key="lectureIndex").lecture
        .header
          .dot {{lecture.location}}
          p {{lecture.start}}
        p {{lecture.name}}
        .author
          div(:style="{backgroundImage: `url('/images/${lecture.img}.png')`}").avatar
          .who
            .name {{lecture.author}}
            .role {{lecture.role}}
          div(:style="{backgroundImage: `url('/images/${lecture.rofl}.svg')`}").rofl
        div(:style="{backgroundImage: `url('/images/${lecture.roflMobile}.svg')`}").rofl-mobile
    .line
    .right
      h1 Список Шерлоков
      TopTable(color="black")
</template>

<script>
import TopTable from '@/components/TopTable.vue';

export default {
  name: 'LandingLecturers',
  components: {
    TopTable,
  },
  data() {
    return {
      lecturers: [
        {
          location: '1 день - зал № 3',
          start: 'Cтарт в 14:30 –',
          name: 'Построение автономной системы обучения аналитиков и продактов работе с Tableau',
          author: 'Артем Давтян',
          role: 'Ведущий аналитик',
          img: 'lecturer-1',
          rofl: 'lecture-rofl-2',
          roflMobile: 'lecture-rofl-mobile-1',
        },
        {
          location: '2 день - зал № 2',
          start: 'Cтарт в 15:15 –',
          name: 'Формирование и развитие команды аналитики приложения. Особенности работы выделенной команды, целеполагание и управление',
          author: 'Эдуард Савинов',
          role: 'Руководитель аналитики мобильного банка Тинькофф',
          img: 'lecturer-2',
          rofl: 'lecture-rofl-1',
          roflMobile: 'lecture-rofl-mobile-2',
        },
      ],
    };
  },
  methods: {},
};
</script>
