const defaultState = {
  currentVideo: null,
};

const mutations = {
  SET_VIDEO: (state, payload) => {
    state.currentVideo = payload;
  },
};
const getters = {};
const actions = {
  setVideo({ commit }, payload) {
    commit('SET_VIDEO', payload);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
