<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
.landing-end {
  @apply flex flex-col w-full relative overflow-hidden items-center;
  height: 90vh;
  background: url('/images/end-bg.svg');
  background-position: top;
  @apply bg-no-repeat bg-cover;
  padding-top: 300px;
  // border: 1px solid red;
  .phone {
    background: url('/images/phone.svg');
    bottom: -20px;
    right: 5vw;
    background-position: right center;
    @apply bg-contain bg-no-repeat w-full h-full absolute;
  }
  .content-wrapper {
    @apply flex flex-col w-full justify-between z-20;
    max-width: 1200px;
    h1 {
      font-weight: 700;
      font-size: 54px;
      line-height: 120%;
      font-family: 'Montserrat', sans-serif;
      color: #FFFFFF;
      // max-width: 550px;
    }
  }
}
.btn-primary {
  margin-top: 48px;
  margin-bottom: 140px;
  width: 209px;
  height: 54px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.phone-mobile {
  background: url('/images/phone-mobile.svg');
  top: -50px;
  display: none;
  background-position: left center;
  height: 400px;
  @apply bg-contain bg-no-repeat w-full absolute;
}
@media screen and (max-width: 1200px) {
  .landing-end {
    height: auto;
    padding-bottom: 100px;
    .content-wrapper {
      padding: 0 10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .landing-end {
    height: 70vh;
    .phone {
      display: none;
    }
    .phone-mobile {
      display: inherit;
      // border: 1px solid red;
    }
    .content-wrapper {
      width: 100%;
      h1 {
        margin-top: 50px;
        font-size: 28px;
      }
    }
  }
}
</style>
<template lang="pug">
.landing-end
  .phone
  .phone-mobile
  .content-wrapper
    h1 Проверим насколько<br /> ты крутой продакт?
    LandingBtn(text="Пройти")
</template>

<script>
import LandingBtn from '@/components/LandingPage/LandingBtn.vue';

export default {
  name: 'LandingEnd',
  components: {
    LandingBtn,
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
