<style lang="scss" scoped>
.share-block {
  @apply absolute;
  bottom: -16px;
  left: calc(50% - 66px);
}
</style>

<template>
  <transition-fade>
    <Conversation
      v-if="userTop"
      supertitle="Ты занял"
      :title="userTop.rank ? `${userTop.rank} место`: ''"
      text="Ну что, Шерлок, Мориарти опять запутал все данные? Или Мориарти это ты?"
    >
      <template #textInner>
        <SocialShare class="share-block" />
      </template>
      <template #buttons>
        <div>
          <router-link class="btn-primary" :to="{name: 'Top'}">Таблица лидеров</router-link>
          <router-link class="btn-secondary mt-8" :to="{name: 'Restart'}">Перепройти</router-link>
        </div>
      </template>
    </Conversation>
  </transition-fade>
</template>

<script>
import useTop from '@/composables/useTop';
import Conversation from '../Conversation.vue';
import TransitionFade from '../UI/TransitionFade.vue';
import SocialShare from '../SocialShare.vue';

export default {
  name: 'Result',
  components: { Conversation, TransitionFade, SocialShare },
  data() {
    return {};
  },
  setup() {
    const { userTop, getTopUser } = useTop();
    return { userTop, getTopUser };
  },
  props: {},
  created() {
    this.getTopUser(this.user.id);
  },
  mounted() {
    console.log('result mounted', this.userTop);
  },
  methods: {},
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
};
</script>
