<template>
  <div class="top-wrapper">
    <h4 class="sher-title ml-12 mb-8">Лидеры</h4>
    <div class="box">
      <TopTable type="gutter" />
    </div>
    <router-link :to="{name: 'Result'}" class="btn-secondary top-btn">Назад</router-link>
  </div>
</template>

<script>
import TopTable from '@/components/TopTable.vue';

export default {
  name: 'Top',
  components: {
    TopTable,
  },
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.top-wrapper {
  @apply h-full;
}
.box {
  height: calc(100% - 124px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(54, 54, 54, 1);
  }
}
.top-btn {
  @apply absolute bottom-12;
  width: calc(100% - 24px);
  left: 12px;
}
</style>
