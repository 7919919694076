<template>
  <div class="px-8">
    <div class="buttons mb-32">
      <button class="time-btn" :class="{current: idx === currentId}"
        @click="currentId = idx"
        v-for="(item, idx) in collection" :key="idx">
        {{ item.title }}
      </button>
    </div>
    <transition-fade>
      <div class="top-table" :class="[`top-table--${type}`, color]" v-if="currentTop.collection">
        <div v-if="!error" class="table">
          <div class="table__row table__row--header">
            <div class="table__col table__col--number">№</div>
            <div class="table__col table__col--player">Игрок</div>
            <div class="table__col table__col--score">Очки</div>
          </div>
          <div v-if="!currentTop.collection.length" class="w-full text-center p-16">
            Нет данных
          </div>
          <div
            class="table__row"
            v-for="(player, idx) in currentTop.collection"
            :key="`top_table_player_${idx}`"
            :class="{ 'table__row--active': player.name === currentPlayer }"
          >
            <div class="table__col table__col--number">{{ player.rank }}</div>
            <div class="table__col table__col--player">{{ player.name }}</div>
            <div class="table__col table__col--score">{{ player.score }}</div>
          </div>
        </div>
        <div v-else class="error">Ошибка загрузки данных...</div>
      </div>
    </transition-fade>
  </div>
</template>

<script>
import TransitionFade from './UI/TransitionFade.vue';

export default {
  components: { TransitionFade },
  name: 'TopTable',
  data() {
    return {
      currentId: 0,
    };
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '',
    },
  },

  computed: {
    collection() {
      return this.$store.state.topTable.statistics;
    },
    players() {
      return this.$store.state.topTable.statistics;
    },
    currentPlayer() {
      return this.$store.state.user.user.username;
    },
    error() {
      return this.$store.state.topTable.error;
    },
    currentTop() {
      return this.collection ? this.collection[this.currentId] : [];
    },
  },

  methods: {
    async getStatistics() {
      await this.$store.dispatch('topTable/fetchPlayers');
    },
  },

  mounted() {
    this.getStatistics().then(() => {
      console.log('collection', this.collection);
    });
  },
};
</script>

<style lang="scss" scoped>
.top-table {
  &.black {
    & .table__col {
      color: #000000;
    }
    & .table__row {
      border-color: #cacaca;
    }
  }
  &--gutter {
    & .title {
      @apply font-normalidad-wide text-20 leading-28 uppercase mb-16;
      font-weight: 350;
      letter-spacing: 0.04em;
      max-width: 241px;
      padding-left: 16px;
    }

    & .table {
      height: auto;
      overflow-y: hidden;
      padding-right: 0;
    }

    & .table__row {
      padding-left: 16px;
      padding-right: 16px;
    }

    & .table__row--active {
      border-radius: 0;
    }

    & .table__col--player {
      flex-basis: 80%;
    }

    & .table__col--score {
      flex-basis: 10%;
    }
  }
}
.title {
  @apply font-medium mb-24 leading-30;
  font-size: 25px;

  @media screen and (min-width: 480px) {
    @apply text-24 leading-28 mb-32;
  }
}
.table {
  @apply block w-full pr-8;
  height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(54, 54, 54, 1);
  }

  &__row {
    @apply flex items-center w-full py-24;
    border-color: rgba(0, 0, 0, 0.2);

    &:not(:last-child) {
      @apply border-b;
    }

    &--active {
      & .table__col {
        color: rgba(0, 0, 0, 0.9);
      }
      @apply relative py-16 -mt-4 border-0;
      background: #ffdd2d;
      border-radius: 10px;

      @media screen and (min-width: 480px) {
        @apply pl-0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &::before {
        @media screen and (min-width: 480px) {
          @apply absolute top-0 h-full;
          content: '';
          left: -33px;
          width: 33px;
          background-color: #083535;
          border-radius: 10px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .table__col.table__col--score {
        @media screen and (min-width: 480px) {
          display: block;
        }
      }

      .table__col.table__col--player {
        margin-left: 10px;
        @media screen and (min-width: 480px) {
          margin-left: 0;
        }
      }
    }

    &--header {
      padding-top: 14px;
      padding-bottom: 14px;

      & .table__col {
        @apply font-normalidad-wide font-normal text-12;
        color: #ffffff;
      }
    }
  }

  &__col {
    @apply text-18 opacity-90;
    border-radius: 5px;
    color: #ffffff;

    &--number {
      @apply pl-8;
      flex-basis: 10%;
    }
    &--player {
      flex-basis: 75%;

      @media screen and (min-width: 480px) {
        flex-basis: 60%;
      }
    }
    &--score {
      flex-basis: 15%;

      @media screen and (min-width: 480px) {
        flex-basis: 30%;
      }
    }
  }
}
.time-btn {
  @apply font-neuehaas-medium mr-8 my-4;
  background: #DCD6CE;
  border: 1px solid #C9C5C0;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 16px;
  color: #525252;
  --size: 40px;
  height: var(--size);
  line-height: var(--size);
  padding: 0 12px;
  &.current {
    background: rgba(255, 255, 255, 0.8);
    color: #000000;
  }
}
</style>
