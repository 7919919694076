<style lang="scss" scoped>
.game-page-container {
  @apply flex justify-center items-center relative;
  height: 100vh;
  background: #000000;
  background-image: url('/images/1280_star.png');
  background-size: contain;
  overflow: hidden;

  &__star {
    @apply absolute w-40;
    left: calc(50% - 23px);
    top: calc(100% - 64px);
  }

  &__blur {
    @apply absolute bg-contain bg-no-repeat bg-center;
    top: calc(50% - 376px);
    left: calc(50% - 370px);
    width: 739px;
    height: 752px;
    background-image: url('/images/ellipse.png');
    z-index: 1;
  }

  &__gray-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('/images/gray-blur.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__constellation-left {
    @apply absolute hidden sm:block;
    right: calc(50% + 163px);
  }
  &__constellation-right {
    @apply absolute hidden sm:block;
    left: calc(50% + 195px);
  }
}

.game-inner-container {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 320px;
  max-height: 570px;
  background-color: black;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1;
}
.counter-container {
  @apply absolute z-50;
  width: calc(100% - 18px);
  top: 30px;
  left: 9px;
  --clip-: 20px;
}
.result-counter {
  @apply absolute top-40 flex;
  left: calc(50% - 44px);
  & img {
    @apply mx-4;
  }
}
</style>

<template>
  <div class="h-full">
    <transition-fade>
      <Conversation
        v-if="!introShown && !isGameStateLoading"
        :supertitle="contentPreview.supertitle"
        :title="contentPreview.title"
        :text="contentPreview.text"
        :image="contentPreview.imageIntro"
        key="intro"
      >
        <template #buttons>
          <button @click="endOfDialog"
            :class="['btn-primary', isLoading ? 'loading' : '']">
            {{ contentPreview.submitText }}
          </button>
        </template>
      </Conversation>
      <Conversation
        v-else-if="showResult && !isGameStateLoading"
        :supertitle="contentPreview.supertitle"
        title="Завершен"
        :text="contentPreview.endText"
        :image="contentPreview.imageOutro"
        key="outro"
      >
        <template #buttons>
          <router-link v-if="isGameFinished" :to="{ name: 'Feedback' }"
            class="btn-primary">
            Результат
          </router-link>
          <!-- <router-link v-else :to="{ name: 'Scan' }"
            class="btn-secondary">
            Ищи в реале
          </router-link> -->
          <button v-else disabled class="btn-secondary">Используй свою камеру</button>
        </template>
        <template #textInner>
          <div class="result-counter">
            <img v-for="i in incorrect"
              :key="`incorrect-${i}`" src="/images/sherlock/failure-rounded.svg" />
            <img v-for="i in correct"
              :key="`correct-${i}`" src="/images/sherlock/success-rounded.svg" />
          </div>
        </template>
      </Conversation>
      <Quiz
        v-else-if="!isGameStateLoading"
        @done="setShowResult"
      />
    </transition-fade>
  </div>
</template>

<script>
import useGameState from '@/composables/useGameState';
import Quiz from '@/components/Game/Quiz.vue';
import levelPreview from '@/assets/text/sherlock/levelPreview.json';
import Conversation from '@/components/Conversation.vue';
import { ref, watch, computed } from '@vue/composition-api';
import useGameQuestions from '@/composables/useGameQuestions';
import TransitionFade from '../components/UI/TransitionFade.vue';

export default {
  components: {
    Conversation,
    Quiz,
    TransitionFade,
  },
  props: {
    id: { type: String },
  },
  setup(props) {
    const stageIds = {
      2: '127bc99935f901a2130d4a56a6cacdb4',
      3: '176adbdfce4c6871c67856e7d6c98476',
    };
    const stageIdsShort = {
      2: '2',
      3: '3',
    };
    const { id } = props;
    const {
      gameStart,
      getGame,
      gameInfo,
      currentStageSerial: initialStageSerial,
      introShown,
      showResult,
      isGameFinished,
      currentStage,
      isLoading: isGameStateLoading,
    } = useGameState();
    const currentStageSerial = ref(initialStageSerial.value);
    watch(initialStageSerial, (val) => {
      currentStageSerial.value = val;
    });
    const { getQuestion, question } = useGameQuestions();
    if (!question.value.id) {
      getQuestion();
    }
    const isLoading = ref(false);
    const answeredQuestions = ref(0);
    const setIntroShown = () => {
      introShown.value = true;
    };
    getGame().then(() => {
      if (!gameInfo.value.id) {
        gameStart();
      }
      // если ответ на сюжетный вопрос был дан
      if (currentStage.value.remained) {
        introShown.value = true;
      }
      if (isGameFinished.value) {
        introShown.value = true;
        showResult.value = true;
      }
      answeredQuestions.value = currentStage.value?.answered_questions;
    });
    const endOfDialog = () => {
      isLoading.value = true;
      setIntroShown();
      getQuestion()
        .then(getGame)
        .then(() => {
          isLoading.value = false;
        });
    };
    const setShowResult = () => {
      showResult.value = true;
      getGame().then(() => {
      });
    };
    watch(isGameStateLoading, (val) => {
      if (!val) {
        if (currentStageSerial.value === 2 && (id !== stageIds['2'] && id !== stageIdsShort['2'])) {
          introShown.value = true;
          showResult.value = true;
          currentStageSerial.value = 1;
        }
        if (currentStageSerial.value === 3 && (id !== stageIds['3'] && id !== stageIdsShort['3'])) {
          introShown.value = true;
          showResult.value = true;
          currentStageSerial.value = 2;
        }
      }
    });

    const currentResult = computed(() => gameInfo
      .value?.stages.find((item) => item.serial === currentStageSerial.value));

    if (id) {
      introShown.value = false;
      showResult.value = false;
    }

    return {
      currentStageSerial,
      gameInfo,
      introShown,
      setIntroShown,
      showResult,
      setShowResult,
      isGameFinished,
      endOfDialog,
      currentStage,
      answeredQuestions,
      isLoading,
      isGameStateLoading,
      currentResult,
    };
  },
  methods: {},
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
    isIntroShown() {
      return this.$store.state.user.isIntroShown;
    },
    contentPreview() {
      switch (this.currentStageSerial) {
        case 1:
          return levelPreview['1'];
        case 2:
          return levelPreview['2'];
        case 3:
          return levelPreview['3'];
        default:
          return undefined;
      }
    },
    conversationImage() {
      return `${this.currentStageSerial}.png`;
    },
    correct() {
      return this.currentResult?.correct_questions || 0;
    },
    incorrect() {
      return this.currentResult?.total_questions - this.correct;
    },
  },
  watch: {
  },
  mounted() {
    if (this.$route.query.token) {
      this.$router.push(this.$route.path);
    }
  },
  destroyed() {
    console.log('destroyed');
    this.introShown = false;
    this.showResult = false;
  },
};
</script>
