import {
  reactive, toRefs,
} from '@vue/composition-api';
import fetchTopUser from '../api/fetchTopUser';

const state = reactive({
  isLoading: false,
  error: null,
  userTop: {},
});

export default function useTop() {
  const getTopUser = async (userId) => {
    state.isLoading = true;
    state.userTop = {};
    state.error = null;
    try {
      const result = await fetchTopUser(userId);
      state.userTop = result.user_rating || {};
    } catch (e) {
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  };

  return {
    ...toRefs(state),
    getTopUser,
  };
}
