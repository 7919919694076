import axios from 'axios';

const defaultState = {
  statistics: null,
  error: null,
};

const mutations = {
  SET_STATISTICS: (state, payload) => {
    state.statistics = payload;
  },
};
const getters = {};
const actions = {
  async fetchPlayers({ commit }) {
    try {
      const { data } = await axios.get('/scoreboard');
      commit('SET_STATISTICS', data.collection);
    } catch (e) {
      console.log('[vuex-user] fetchPlayers error', e);
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
