import axios from 'axios';

const sendAnswer = async (questionId, answerId) => {
  const response = await axios.post('/game/questions/answer', {
    question_id: questionId,
    answer_id: answerId,
  });
  return response.data;
};

export default sendAnswer;
