<style lang="scss" scoped>
.qiuz {
  @apply h-full;
}
.text-main {
  @apply flex-grow font-neuehaas-medium;
  padding: 12px 16px 20px;
  background: #f2f2f2;
  box-shadow: 6px 6px 0px #000000;
  border-radius: 2px;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  .text-counter {
    @apply font-tinkoff-sans;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    color: #b5b5b5;
  }
}
</style>

<template>
  <div
    class="quiz h-full"
    :class="{
      'game-main--success': question.is_right === true,
      'game-main--failure': question.is_right === false,
    }"
  >
    <transition-fade>
      <Conversation
        class="h-full"
        v-if="isQuestionAnswered" key="result"
        :title="question.is_right ? 'Верно' : 'Неверно'"
        :text="question.clarification_text"
        :type="question.is_right ? 'success' : 'failure'"
      >
        <template #buttons>
          <button
            @click="continueQuiz"
            class="btn-primary"
            :disabled="continueLoading"
            :class="[question.is_right ? 'btn-primary--success' : 'btn-primary--failure',
              continueLoading ? 'loading' : '']"
          >
            Продолжить
          </button>
        </template>
      </Conversation>
      <div v-else-if="!continueLoading" class="game-main flex flex-col h-full" key="answer">
        <div class="text-main">
          <div class="text-counter">
            Вопрос {{ currentQuestion }} из {{ currentStage.total_questions }}
          </div>
          <vue-markdown :source="question.text" @rendered="update"> </vue-markdown>
        </div>
        <div class="-my-16">
          <button
            class="btn-option"
            v-for="item in question.answers"
            :key="item.id"
            :disabled="isLoading"
            :class="{
              selected: pickedAnswerId === item.id,
            }"
            @click="chooseAnswer(item.id)"
          >
            <vue-markdown :source="item.text" @rendered="update"> </vue-markdown>
          </button>
        </div>
        <button class="btn-primary mt-24"
          :class="{
            loading: isLoading,
          }"
          @click="sendAnswer" :disabled="!pickedAnswerId || isLoading">
          Ответить
        </button>
        <div style="height: 12px; width: 100%; color: #000">_</div>
      </div>
    </transition-fade>
  </div>
</template>

<script>
import Prism from 'prismjs';
import useGameQuestions from '@/composables/useGameQuestions';
import useGameState from '@/composables/useGameState';
import { computed, ref } from '@vue/composition-api';
import VueMarkdown from 'vue-markdown';
import Conversation from '@/components/Conversation.vue';
import TransitionFade from '../UI/TransitionFade.vue';
import 'prismjs/themes/prism-tomorrow.css'; // theme
import 'prismjs/components/prism-go.min'; // language

export default {
  name: 'Quiz',
  components: { TransitionFade, VueMarkdown, Conversation },
  data() {
    return {
      continueLoading: false,
    };
  },
  setup() {
    const pickedAnswerId = ref(null);
    const {
      answerQuestion, getQuestion, question, isLoading,
    } = useGameQuestions();
    const { currentStage } = useGameState();
    const currentQuestion = ref(currentStage.value.answered_questions + 1);
    if (!question.value.id) {
      getQuestion();
    }
    const chooseAnswer = (id) => {
      pickedAnswerId.value = id;
    };
    const sendAnswer = () => {
      answerQuestion(pickedAnswerId.value).then(() => {
        pickedAnswerId.value = null;
        currentQuestion.value += 1;
      });
    };
    const isQuestionAnswered = computed(() => [true, false].includes(question.value.is_right));
    return {
      answerQuestion,
      getQuestion,
      question,
      isQuestionAnswered,
      isLoading,
      chooseAnswer,
      pickedAnswerId,
      currentStage,
      sendAnswer,
      currentQuestion,
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    continueQuiz() {
      this.continueLoading = true;
      this.getQuestion().then(() => {
        if (!this.question.id) {
          this.$emit('done');
        }
        this.continueLoading = false;
      });
    },
    update() {
      this.$nextTick(() => {
        Prism.highlightAll();
      });
    },
  },
  computed: {},
};
</script>
