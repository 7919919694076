import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import Feedback from '@/components/Result/Feedback.vue';
import Result from '@/components/Result/Result.vue';
import Top from '@/components/Result/Top.vue';
import LayoutGame from '@/layouts/LayoutGame.vue';

import GamePage from '../pages/GamePage.vue';
import RestartPage from '../pages/RestartPage.vue';
import ScanPage from '../pages/ScanPage.vue';
import LandingPage from '../pages/LandingPage.vue';

Vue.use(VueRouter);

const beforeEnter = () => async (to, from, next) => {
  await store.dispatch('user/fetchUser');
  if (store.getters.token) {
    store.commit('user/SET_TOKEN', store.getters.token);
    await store.dispatch('user/fetchUser');
    // await store.dispatch('game/fetchGame', true);
    next();
  } else if (to.name !== 'LandingPage') {
    next({ name: 'LandingPage' });
  }
  next();
};

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    beforeEnter: beforeEnter(),
  },
  {
    path: '/',
    component: LayoutGame,
    meta: {
      bodyClass: 'game-layout--bg',
    },
    beforeEnter: beforeEnter(),
    children: [
      {
        path: 'game',
        name: 'GamePage',
        component: GamePage,
        props: (route) => ({ id: route.query.id }),
        meta: {
          // bodyClass: 'game-layout--bg',
        },
      },
      {
        path: 'feedback',
        name: 'Feedback',
        component: Feedback,
        meta: {
          bodyClass: 'game-layout--theend',
        },
      },
      {
        path: 'result',
        name: 'Result',
        component: Result,
        meta: {
          bodyClass: 'game-layout--theend',
        },
      },
      {
        path: 'top',
        name: 'Top',
        component: Top,
        meta: {
          bodyClass: 'game-layout--theend',
        },
      },
      {
        path: 'restart',
        name: 'Restart',
        component: RestartPage,
        meta: {
          bodyClass: 'game-layout--theend',
        },
      },
      {
        path: 'scan',
        name: 'Scan',
        component: ScanPage,
        meta: {
          bodyClass: 'game-layout--theend',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
