<!-- eslint-disable max-len -->

<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4591 15.8327H4.75081C3.87636 15.8327 3.16748 15.1238 3.16748 14.2493V5.54102C3.16748 4.66656 3.87636 3.95768 4.75081 3.95768H7.91748V5.54102H4.75081V14.2493H13.4591V11.0827H15.0425V14.2493C15.0425 15.1238 14.3336 15.8327 13.4591 15.8327ZM9.26331 10.8507L8.14706 9.73131L13.129 4.74935H10.2925V3.16602H15.8341V8.70768H14.2508V5.86956L9.26331 10.8507Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconOpen',
  data() {
    return {};
  },
};
</script>
