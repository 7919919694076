<style lang="scss" scoped>
.conversation {
  @apply h-full flex flex-col px-16 pt-8 pb-12 bg-contain bg-center text-14 leading-18;
  &.success {
    & .title {
      background: #83D148;
      color: #000000;
    }
    & .text-container {
      background: #DAFFBE;
    }
  }
  &.failure {
    & .title {
      background: #FF5A56;
      color: #000000;
    }
    & .text-container {
      background: #FFBDBB;
    }
  }
  .title {
    @apply mt-48 px-8 w-max mx-auto relative font-neuehaas-bold;
    --height: 48px;
    height: var(--height);
    line-height: var(--height);
    background: #ffdd2d;
    color: #000000;
    font-size: 40px;
  }
  .supertitle {
    @apply absolute w-full text-center font-tinkoff-sans;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    top: -28px;
    left: 0;
  }
  .text-container {
    @apply mt-12 w-full flex items-center text-center relative font-neuehaas-bold;
    min-height: 212px;
    background: #E7E2DC;
    border-radius: 10px;
    & > div {
      @apply mx-auto;
    }
  }
  .text {
    @apply px-24 py-32;
    color: #000000;
  }
}
.conversation-text {
  @apply text-14 leading-18 text-left mb-32 relative;
  color: #ffffff;
}
.conversation-text-content {
  @apply relative z-10;
  &:after {
    @apply block mt-16 text-12 leading-16;
  }
}
</style>

<template>
  <div
    class="conversation"
    :class="type"
  >
    <div class="flex-grow">
      <div class="title" v-if="title">
        {{ title }}
        <div class="supertitle" v-if="supertitle">
          {{ supertitle }}
        </div>
      </div>
      <div
        class="text-container"
        v-if="text"
      >
        <div class="text" v-html="text"></div>
        <slot name="textInner" />
      </div>
      <img :src="image" class="mx-auto" alt="" />
    </div>
    <div class="conversation-buttons" ref="dialogButtons">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
// import gsap from 'gsap';
import useGameState from '@/composables/useGameState';
import { ref } from '@vue/composition-api';

export default {
  name: 'Conversation',
  components: {},
  data() {
    return {
      // индекс первой фразы
      showPreview: true,
    };
  },
  setup() {
    const { answerStoryQuestion, currentStageSerial, isLoading } = useGameState();
    const picked = ref(null);
    return {
      answerStoryQuestion,
      currentStageSerial,
      isLoading,
      picked,
    };
  },
  props: {
    supertitle: { type: String, default: '' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    image: { type: String },
    type: {
      type: String,
      default: 'default',
      validator: (val) => ['default', 'success', 'failure'].includes(val),
    },
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
