var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quiz h-full",class:{
    'game-main--success': _vm.question.is_right === true,
    'game-main--failure': _vm.question.is_right === false,
  }},[_c('transition-fade',[(_vm.isQuestionAnswered)?_c('Conversation',{key:"result",staticClass:"h-full",attrs:{"title":_vm.question.is_right ? 'Верно' : 'Неверно',"text":_vm.question.clarification_text,"type":_vm.question.is_right ? 'success' : 'failure'},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('button',{staticClass:"btn-primary",class:[_vm.question.is_right ? 'btn-primary--success' : 'btn-primary--failure',
            _vm.continueLoading ? 'loading' : ''],attrs:{"disabled":_vm.continueLoading},on:{"click":_vm.continueQuiz}},[_vm._v(" Продолжить ")])]},proxy:true}],null,false,1829244775)}):(!_vm.continueLoading)?_c('div',{key:"answer",staticClass:"game-main flex flex-col h-full"},[_c('div',{staticClass:"text-main"},[_c('div',{staticClass:"text-counter"},[_vm._v(" Вопрос "+_vm._s(_vm.currentQuestion)+" из "+_vm._s(_vm.currentStage.total_questions)+" ")]),_c('vue-markdown',{attrs:{"source":_vm.question.text},on:{"rendered":_vm.update}})],1),_c('div',{staticClass:"-my-16"},_vm._l((_vm.question.answers),function(item){return _c('button',{key:item.id,staticClass:"btn-option",class:{
            selected: _vm.pickedAnswerId === item.id,
          },attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.chooseAnswer(item.id)}}},[_c('vue-markdown',{attrs:{"source":item.text},on:{"rendered":_vm.update}})],1)}),0),_c('button',{staticClass:"btn-primary mt-24",class:{
          loading: _vm.isLoading,
        },attrs:{"disabled":!_vm.pickedAnswerId || _vm.isLoading},on:{"click":_vm.sendAnswer}},[_vm._v(" Ответить ")]),_c('div',{staticStyle:{"height":"12px","width":"100%","color":"#000"}},[_vm._v("_")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }