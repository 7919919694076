<style lang="scss">
::-webkit-scrollbar{
  width: 0px !important;
}
.landing {
  @apply flex flex-col w-full;
  max-width: 100vw;
  background: #e7e2dc;
  .footer {
    @apply w-full flex justify-center;
    background: #000;
  }
}
@media screen and (max-width: 768px) {
  .landing {
    .footer {
      padding: 0 10px;
    }
  }
}
</style>
<template lang="pug">
.landing
  LandingCover
  LandingPrizes
  LandingLecturers
  LandingEnd
  .footer
    FooterContent()
</template>

<script>
import LandingCover from '@/components/LandingPage/LandingCover.vue';
import LandingPrizes from '@/components/LandingPage/LandingPrizes.vue';
import LandingLecturers from '@/components/LandingPage/LandingLecturers.vue';
import LandingEnd from '@/components/LandingPage/LandingEnd.vue';
import FooterContent from '@/components/LandingPage/FooterContent.vue';

export default {
  name: 'LandingPage',
  components: {
    LandingCover,
    LandingPrizes,
    LandingLecturers,
    LandingEnd,
    FooterContent,
  },

  methods: {},
};
</script>
