import {
  ref, reactive, toRefs, computed, watch,
} from '@vue/composition-api';
import fetchQuestion from '../api/fetchQuestion';
import sendAnswer from '../api/sendAnswer';
import useGameState from './useGameState';

const answerId = ref(null);
const questionResponse = ref(null);
const state = reactive({
  isLoading: false,
  error: null,
  question: {},
});
const { currentStageSerial } = useGameState();

export default function useGameQuestions() {
  const getQuestion = async () => {
    state.isLoading = true;
    state.question = {};
    state.error = null;
    answerId.value = null;
    questionResponse.value = null;
    try {
      const result = await fetchQuestion(currentStageSerial.value);
      state.question = result;
    } catch (e) {
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  };

  const answerQuestion = async (answId) => {
    state.isLoading = true;
    try {
      const result = await sendAnswer(state.question.id, answId);
      state.question = result;
    } catch (e) {
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  };

  const setAnswerId = (id) => {
    answerId.value = id;
  };

  const rightAnswerId = computed(() => questionResponse.value?.right_answer_id);

  const rightAnswer = computed(() => {
    if (!rightAnswerId.value) {
      return {};
    }
    return state.question.answers.find((item) => item.id === rightAnswerId.value);
  });

  const answer = computed(() => {
    if (!answerId.value) {
      return {};
    }
    return state.question.answers.find((item) => item.id === answerId.value);
  });

  watch(answerId, (val) => { if (val) { answerQuestion(val); } });

  watch(rightAnswerId, (val) => {
    if (val && val !== answerId.value) {
      window.navigator.vibrate(300);
    }
  });

  return {
    ...toRefs(state),
    getQuestion,
    answerQuestion,
    setAnswerId,
    rightAnswerId,
    rightAnswer,
    answerId,
    answer,
  };
}
