<!-- eslint-disable max-len -->
<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1943 8L12 11.1943L8.80571 8L8 8.80571L11.1943 12L8 15.1943L8.80571 16L12 12.8057L15.1943 16L16 15.1943L12.8057 12L16 8.80571L15.1943 8Z"
      fill="#58595B"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCloseSquare',
  components: {},
  data() {
    return {};
  },
};
</script>
