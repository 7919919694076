import axios from 'axios';

const sendAnswerStoryQuestion = async ({ gameStage, storyQuestionAnswer }) => {
  const response = await axios.post(`/game/story-question/${gameStage}/answer`, {
    story_question_answer: storyQuestionAnswer,
  });
  return response.data;
};

export default sendAnswerStoryQuestion;
