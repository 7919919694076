<!-- eslint-disable max-len -->
<template>
  <svg
    width="77"
    height="16"
    viewBox="0 0 77 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M6.88405 5.7237V3.42981H4.83598C4.76536 3.42981 4.69473 3.42981 4.5888 3.42981C2.04636 3.42981 -0.00170898 5.47667 -0.00170898 8.01759C-0.00170898 10.5585 2.04636 12.6054 4.5888 12.6054C5.43628 12.6054 6.21313 12.3936 6.88405 12.0054V13.77H9.17931V5.7237H6.88405ZM6.21313 9.60567C5.78939 10.0292 5.22441 10.2762 4.5888 10.2762C3.31758 10.2762 2.29355 9.25276 2.29355 7.9823C2.29355 7.34707 2.54073 6.78242 2.96447 6.35893C3.38821 5.93545 3.95319 5.68841 4.5888 5.68841C4.62411 5.68841 4.69474 5.68841 4.73005 5.68841C5.29503 5.7237 5.82471 5.97074 6.21313 6.35893C6.63687 6.78242 6.88405 7.34707 6.88405 7.9823C6.88405 8.61753 6.63687 9.21747 6.21313 9.60567Z"
        fill="white"
      />
      <path
        d="M39.2645 11.2291C38.4523 12.0407 37.2871 12.5701 36.0158 12.5701C33.4734 12.5701 31.4253 10.5232 31.4253 7.98232C31.4253 6.71185 31.9197 5.58255 32.7671 4.73558C33.5793 3.8886 34.7446 3.39453 36.0158 3.39453H38.3111V5.68842H36.0158C35.3802 5.68842 34.8152 5.93546 34.3915 6.35895C33.9677 6.78243 33.7206 7.34708 33.7206 7.98232C33.7206 9.25278 34.7446 10.2762 36.0158 10.2762C36.6514 10.2762 37.2164 10.0292 37.6402 9.60568L39.2645 11.2291Z"
        fill="white"
      />
      <path
        d="M6.88402 13.6995H1.72852V15.9934H6.88402V13.6995Z"
        fill="white"
      />
      <path d="M51.1292 10.2764H48.834V12.5703H51.1292V10.2764Z" fill="white" />
      <path
        d="M46.5392 7.98252H48.8345V10.2764H44.244V12.5703H41.9487V0.00683594H44.244V7.02967L47.8811 3.43003H51.1298L46.5392 7.98252Z"
        fill="white"
      />
      <path
        d="M56.4968 3.42981C56.4261 3.42981 56.3908 3.42981 56.3202 3.42981C56.2496 3.42981 56.2143 3.42981 56.1437 3.42981C54.9431 3.4651 53.8837 3.95917 53.0716 4.77085C52.2594 5.58254 51.7297 6.74713 51.7297 8.01759C51.7297 10.5585 53.7778 12.6054 56.3202 12.6054C57.5914 12.6054 58.7214 12.076 59.5689 11.2643C60.381 10.4526 60.9107 9.28806 60.9107 8.01759C60.9107 5.54725 58.9686 3.53568 56.4968 3.42981ZM58.6155 10.2762H54.025V5.68841H58.6155V10.2762Z"
        fill="white"
      />
      <path d="M40.6063 5.72375H38.311V8.01765H40.6063V5.72375Z" fill="white" />
      <path
        d="M19.8434 5.7237H17.5481V3.42981H15.2529C13.9816 3.42981 12.8517 3.95917 12.0042 4.77085C11.192 5.58254 10.6624 6.74713 10.6624 8.01759C10.6624 10.5585 12.7104 12.6054 15.2529 12.6054H17.5481V10.2762H15.2529C14.4054 10.2762 13.6638 9.81742 13.2754 9.14689H19.8434V5.7237ZM13.1342 7.17062C13.1695 7.06475 13.2401 6.95887 13.2754 6.853C13.3814 6.67655 13.4873 6.53539 13.6285 6.39422C14.0523 5.97074 14.6173 5.7237 15.2529 5.7237C15.3235 5.7237 15.3588 5.7237 15.4294 5.7237C15.9944 5.75899 16.5241 6.00603 16.8772 6.39422C17.0184 6.53539 17.1244 6.67655 17.2303 6.853C17.3009 6.95887 17.3363 7.06475 17.3716 7.17062H13.1342Z"
        fill="white"
      />
      <path
        d="M30.2255 5.7237H27.9302V3.42981H25.6349C24.3637 3.42981 23.2338 3.95917 22.3863 4.77085C21.5741 5.58254 21.0444 6.74713 21.0444 8.01759C21.0444 10.5585 23.0925 12.6054 25.6349 12.6054H27.9302V10.3115H25.6349C24.7875 10.3115 24.0459 9.85271 23.6575 9.18218H30.2255V5.7237ZM23.5162 7.17062C23.5516 7.06475 23.6222 6.95887 23.6575 6.853C23.7634 6.67655 23.8694 6.53539 24.0106 6.39422C24.4344 5.97074 24.9993 5.7237 25.6349 5.7237C25.7056 5.7237 25.7409 5.7237 25.8115 5.7237C26.3765 5.75899 26.9062 6.00603 27.2593 6.39422C27.4005 6.53539 27.5065 6.67655 27.6124 6.853C27.683 6.95887 27.7183 7.06475 27.7536 7.17062H23.5162Z"
        fill="white"
      />
    </g>
    <path
      d="M64.0413 3.27344H74.1194L76.1792 5.11404V12.947H64.0413V3.27344Z"
      fill="white"
    />
    <path d="M66.9104 10.8138V5.48047H67.9026V10.8138H66.9104Z" fill="black" />
    <path
      d="M69.4312 10.8138V5.48047H71.3774C72.1051 5.48047 72.7182 5.73698 73.2169 6.24999C73.7206 6.75793 73.9725 7.39031 73.9725 8.14714C73.9725 8.90396 73.7206 9.53888 73.2169 10.0519C72.7182 10.5598 72.1051 10.8138 71.3774 10.8138H69.4312ZM70.4234 9.86142H71.3774C71.8405 9.86142 72.2221 9.70396 72.5223 9.38904C72.8276 9.07412 72.9803 8.66015 72.9803 8.14714C72.9803 7.63412 72.8276 7.22015 72.5223 6.90523C72.2221 6.59031 71.8405 6.43285 71.3774 6.43285H70.4234V9.86142Z"
      fill="black"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="60.9103" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconGeeckoId',
  data() {
    return {};
  },
};
</script>
