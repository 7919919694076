<style lang="scss" scoped>
</style>

<template>
    <div class="-mx-4 flex">
      <a
        v-for="social in socialNetworks"
        :key="social"
        :href="shareUrlLink(social)"
        class="mx-4"
        target="_blank"
      >
        <img :src="`/images/sherlock/icon-${social}.svg`" :alt="social" />
      </a>
      </div>
</template>

<script>

const DEFAULT_SOCIAL_NETWORKS = ['fb', 'vk', 'tw'];
const FB_BASE_SHARE_LINK = 'https://www.facebook.com/sharer/sharer.php?u=';
const VK_BASE_SHARE_LINK = 'https://vk.com/share.php?url=';
const TW_BASE_SHARE_LINK = 'https://www.twitter.com/share?url=';

export default {
  name: 'SocialShare',
  components: {},
  data() {
    return {};
  },
  props: {
    socialNetworks: {
      type: Array,
      validator(value) {
        return value.every((item) => DEFAULT_SOCIAL_NETWORKS.includes(item));
      },
      default: () => ['fb', 'vk', 'tw'],
    },
  },
  methods: {
    shareUrlLink(platform) {
      const url = window.location.origin;
      switch (platform) {
        case 'tw':
          return TW_BASE_SHARE_LINK + url;
        case 'vk':
          return VK_BASE_SHARE_LINK + url;
        case 'fb':
          return FB_BASE_SHARE_LINK + url;
        default:
          return '';
      }
    },
  },
};
</script>
