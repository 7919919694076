<style lang="scss" scoped></style>

<template>
  <Conversation
    supertitle="Перепройти"
    title="Еще раз?"
    text="Повторное прохождение не учитывается в списке лидеров"
  >
    <template #buttons>
      <div>
        <router-link :to="{name: 'Result'}" class="btn-primary">Назад</router-link>
        <button class="btn-secondary mt-8" @click="restart">Перепройти</button>
      </div>
    </template>
  </Conversation>
</template>

<script>
import useTop from '@/composables/useTop';
import useGameState from '@/composables/useGameState';
import Conversation from '@/components/Conversation.vue';

export default {
  name: 'Result',
  components: { Conversation },
  data() {
    return {};
  },
  setup() {
    const { userTop, getTopUser } = useTop();
    const {
      gameStart,
    } = useGameState();
    return { userTop, getTopUser, gameStart };
  },
  props: {},
  created() {
    this.getTopUser(this.user.id);
  },
  mounted() {},
  methods: {
    restart() {
      console.log('restart');
      this.gameStart().then(() => {
        this.$router.push({ name: 'GamePage' });
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters['user/user'];
    },
  },
};
</script>
