<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
.landing-cover {
  @apply flex relative flex-col w-full items-center;
  // height: 70vh;
  max-width: 100vw;
  overflow: hidden;
  background:#1a1a1b;
  &:before {
    content: '';
    position: absolute;
    width: 50vw;
    height: 50vw;
    right: -10vw;
    top: -20vw;
    border-radius: 50%;
    background: linear-gradient(0deg, #DAFFBE, #DAFFBE), #FFEF9D;
  }
  .content-wrapper {
    @apply flex flex-row w-full z-20;
    max-width: 1200px;
    .left {
      @apply flex flex-col items-start justify-start;
      width: 40%;
      .tinkoff-logo {
        background: url('/images/its_tinkoff.svg');
        width: 144px;
        height: 46px;
        margin-bottom: 90px;
        margin-top: 30px;
        @apply bg-contain bg-no-repeat bg-center;
      }
      h3 {
        color: #FFFFFF;
        opacity: 0.7;
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 17px;
        font-family: 'TinkoffSans';
      }
      h1 {
        font-weight: 700;
        font-size: 45px;
        color: #fff;
        margin-bottom: 48px;
        line-height: 120%;
        font-family: 'Montserrat', sans-serif;
        max-width: 500px;
        span {
          color: #000000;
          line-height: 120%;
          background: #DAFFBE;
        }
      }
      .btn-primary {
        margin-bottom: 140px;
        width: 191.28px;
        height: 51.71px;
        font-size: 18px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .right {
      @apply w-full relative;
      width: 60%;
      // border: 1px solid red;
    }
  }
}
.newspaper {
  background: url('/images/newspaper.png');
  // border: 1px solid red;
  // width: 150px;
  -webkit-filter: drop-shadow(0px 15px 30px #222);
  filter: drop-shadow(0px 15px 30px #222);
  width: 45vw;
  right: 10vw;
  aspect-ratio: 1/0.7;
  @apply absolute bg-center bg-no-repeat bg-contain;
}
.cover-text {
  background: url('/images/cover-text.svg');
  // border: 1px solid red;
  width: 40%;
  aspect-ratio: 1/0.75;
  top: 5%;
  right: -5%;
  @apply bg-contain bg-center z-30 bg-no-repeat absolute;
}
@media screen and (max-width: 1200px) {
  .landing-cover {
    .content-wrapper {
      padding: 0 10px;
      @apply flex-col;
      .left {
        @apply w-full;
      }
    }
  }
}
.newspaper-mobile {
  background: url('/images/newspaper-mobile.png');
  background-position: right center;
  height: 580px;
  display: none;
  @apply w-full bg-no-repeat bg-contain;
}
@media screen and (max-width: 768px) {
  .landing-cover {
    &:before {
      content: none;
    }
    .content-wrapper {
      .left {
        h3 {
          font-size: 18px;
        }
        h1 {
          font-size: 32px;
        }
        .btn-primary {
          margin-bottom: 20px;
        }
      }
    }
    .newspaper {
      display: none;
    }
  }
  .newspaper-mobile {
    display: inherit;
    // border: 1px solid red;
  }
}
</style>
<template lang="pug">
.landing-cover
  .newspaper
    .cover-text
  .content-wrapper
    .left
      .tinkoff-logo
      h3 Квиз на 10 минут
      h1 <span>Ты продуктовый Шерлок? </span>Разгадай загадку
      LandingBtn(text="Начать")
    .newspaper-mobile
    //- .right
</template>

<script>
import LandingBtn from '@/components/LandingPage/LandingBtn.vue';

export default {
  name: 'LandingCover',
  components: {
    LandingBtn,
  },

  methods: {},
};
</script>
