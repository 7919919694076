// eslint-disable-next-line import/extensions
import '@/setup/installCompositionApi.js';
import Vue from 'vue';
import VueGtm from 'vue-gtm';
import axios from 'axios';
import VueAxios from 'vue-axios';
import InputMask from 'vue-input-mask';
import VModal from 'vue-js-modal';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/fonts.scss';
import './assets/tailwind.css';
import '@/css/app.scss';
import './styles/swiper.scss';
import 'animate.css';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VModal);
Vue.component('InputMask', InputMask);

// Axios
axios.defaults.baseURL = `${process.env.VUE_APP_SERVER_URL}/api`;
axios.defaults.withCredentials = true;

if (process.env.VUE_APP_GTM && process.env.VUE_APP_GTM.length) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM,
  });
}

// Auth
const uriParams = new URLSearchParams(window.location.search);
const authToken = uriParams.get('token');

if (authToken) {
  store.commit('user/SET_TOKEN', authToken);
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
