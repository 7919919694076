<style lang="scss" scoped>
.btn-primary {
  // max-width: 140px;
}
</style>
<template lang="pug">
button(@click="go").btn-primary {{text}}
</template>

<script>
export default {
  name: 'LandingCover',
  props: {
    text: { type: String },
  },
  computed: {
    authUrl() {
      return `${process.env.VUE_APP_SERVER_URL}/oauth/redirect?redirect_url=${window.location.origin}/game`;
    },
  },
  methods: {
    go() {
      document.location.href = this.authUrl;
    },
  },
};
</script>
