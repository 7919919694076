<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  created() {
    const toPreload = ['/images/intro_1.gif', '/images/outro_1.gif', '/images/outro_2.gif',
      '/images/outro_static.jpg', 'images/cardBack.svg'];
    toPreload.forEach((i) => {
      const preloadLink = document.createElement('link');
      preloadLink.href = i;
      preloadLink.rel = 'preload';
      preloadLink.as = 'image';
      document.head.appendChild(preloadLink);
    });
  },
};
</script>

<style lang="scss">
</style>
