<style lang="scss" scoped>
.video {
  @apply w-full;
  height: 320px;
}
</style>

<template>
  <div class="flex flex-col h-full p-16">
    <div class="sher-title">
      Найди QR код
    </div>
    <div class="flex-grow">
      <video class="video" ref="video"></video>
    </div>

    <router-link class="btn-secondary" :to="{ name: 'GamePage' }">Назад</router-link>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import QrScanner from 'qr-scanner';

export default {
  name: 'ScanPage',
  components: {},
  data() {
    return {
      stopScan: false,
    };
  },
  setup() {},
  props: {},
  created() {},
  methods: {
    parseLink(url) {
      if (!this.stopScan) {
        const u = new URL(url);
        const id = u.searchParams.get('id');
        console.log('id', id);
        if (id) {
          this.$router.push({ name: 'GamePage', query: { id } });
        }
      }
      this.stopScan = true;
    },
  },
  mounted() {
    const qrScanner = new QrScanner(this.$refs.video, (result) => this.parseLink(result));
    qrScanner.start();
  },
  computed: {},
};
</script>
