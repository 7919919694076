<template>
  <div class="game-page-container">
    <div class="absolute top-0 left-0" style="z-index: 9999" v-if="isDev">
      <span class="text-green"> current stage: {{ currentStageSerial }} </span>
      <button @click="gameRestart" class="text-red mr-12">restart</button>
      <button @click="gameStart" class="text-red">start</button>
    </div>
    <div class="game-inner-container" id="game" ref="game">
      <div class="h-full relative">
        <ModalSettings />
        <Header />
        <div class="main-content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game-page-container {
  @apply flex justify-center items-center relative font-neuehaas-medium;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background: #1A1A1B;

  &__star {
    @apply absolute w-40;
    left: calc(50% - 23px);
    top: calc(100% - 64px);
  }
}

.game-inner-container {
  @apply font-normalidad h-full w-full overflow-hidden;
  background-color: #000000;
  max-width: 100vw;
  height: 100%;
  z-index: 1;
  color: #ffffff;
}
.main-content {
  @apply absolute w-full left-0 overflow-auto;
  --top-indent: 52px;
  height: calc(100% - var(--top-indent));
  top: var(--top-indent);
}

@screen sm {
  .game-page-container {
    @apply bg-cover;
    background-image: url('/images/sherlock/game-bg.png');
  }
  .game-inner-container {
    max-width: 320px;
    height: 570px;
    border-radius: 8px;
}
}
</style>

<script>
import useGameState from '@/composables/useGameState';
import Header from '@/components/Header.vue';
import ModalSettings from '@/components/Modals/ModalSettings.vue';

export default {
  components: { Header, ModalSettings },
  setup() {
    const {
      gameRestart, gameInfo, currentStageSerial, introShown, gameStart,
    } = useGameState();
    return {
      gameRestart,
      currentStageSerial,
      gameInfo,
      introShown,
      gameStart,
    };
  },
  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development';
    },
  },
  methods: {
    resize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  mounted() {
    this.resize();
    // We listen to the resize event
    window.addEventListener('resize', this.resize);
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
};
</script>
