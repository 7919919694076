import {
  reactive, toRefs, computed,
} from '@vue/composition-api';
import fetchGame from '../api/fetchGame';
import restart from '../api/restart';
import startNewGame from '../api/startNewGame';
import sendAnswerStoryQuestion from '../api/sendAnswerStoryQuestion';

const state = reactive({
  isLoading: false,
  error: null,
  gameInfo: {
    id: null,
  },
  introShown: false,
  showResult: false,
});

export default function useGameState() {
  const getGame = async () => {
    state.isLoading = true;
    state.gameInfo = {};
    state.error = null;
    try {
      const result = await fetchGame();
      state.gameInfo = result;
    } catch (e) {
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  };

  const currentStageSerial = computed(() => {
    // если игры нет, то это первый этап
    if (!state.gameInfo.id) {
      return 1;
    }
    // текущий этап тот, на котором не все вопросы отвечены
    const notCompletedStage = state.gameInfo.stages.find(
      (item) => item.answered_questions < item.total_questions,
    );
    if (notCompletedStage) {
      return notCompletedStage.serial;
    }
    // если все отвечены, то этап последний
    return state.gameInfo.stages.length;
  });

  const currentStage = computed(
    () => state.gameInfo?.stages?.find((item) => item.serial === currentStageSerial.value),
  );

  const lastPastStage = computed(() => {
    if (state.gameInfo?.stages) {
      return [...state.gameInfo.stages].sort((a, b) => b.serial - a.serial)
        .find((item) => item.answered_questions === item.total_questions);
    }
    return undefined;
  });

  const isGameFinished = computed(() => {
    // если игры нет, то это первый этап
    if (!state.gameInfo.id) {
      return false;
    }
    // текущий этап тот, на котором не все вопросы отвечены
    const notCompletedStage = state.gameInfo.stages.find(
      (item) => item.answered_questions < item.total_questions,
    );
    if (notCompletedStage) {
      return false;
    }
    if (
      state.gameInfo.stages.filter(
        (item) => item.answered_questions === item.total_questions,
      ).length === state.gameInfo.stages.length
    ) {
      // если все отвечены, то квиз завершен
      return true;
    }
    return false;
  });

  const answerStoryQuestion = async (storyQuestionAnswer) => {
    state.isLoading = true;
    // state.gameInfo = {};
    // state.error = null;
    try {
      await sendAnswerStoryQuestion(
        { gameStage: currentStageSerial.value, storyQuestionAnswer },
      );
      // state.gameInfo = result;
    } catch (e) {
      // state.error = e;
    } finally {
      state.isLoading = false;
    }
  };

  const gameRestart = async () => {
    try {
      await restart();
    } catch (e) {
      console.log('restart error', e);
    }
  };

  const gameStart = async () => {
    try {
      await startNewGame();
    } catch (e) {
      console.log('restart error', e);
    }
  };

  return {
    ...toRefs(state),
    getGame,
    currentStageSerial,
    answerStoryQuestion,
    gameRestart,
    isGameFinished,
    gameStart,
    currentStage,
    lastPastStage,
  };
}
