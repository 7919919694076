import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import getters from './getters';
// eslint-disable-next-line import/no-cycle
import user from './modules/user';
import video from './modules/video';
import topTable from './modules/topTable';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    const saveState = {};
    saveState.user = {
      authToken: state.user.authToken,
      isIntroShown: state.user.isIntroShown,
    };

    return saveState;
  },
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    video,
    topTable,
  },
  getters,
  plugins: [vuexLocal.plugin],
});
