<template>
  <header class="header">
    <a href="https://www.tinkoff.ru/career/it/" target="_blank">
      <img src="/images/sherlock/company-logo.svg" alt="tinkoff-logo" />
    </a>
    <slot />
    <button class="menu-button" @click="openSettings">
      Меню
    </button>
  </header>
</template>

<script>

export default {
  name: 'Header',
  components: {},
  props: {},

  methods: {
    openSettings() {
      this.$modal.show('settings-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  @apply absolute top-12 w-full flex justify-between items-center px-12;
  .menu-button {
    @apply font-tinkoff-sans px-16;
    --height: 20px;
    line-height: calc(var(--height) - 2px);
    height: var(--height);
    font-size: 12px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 2px;
  }
}
</style>
